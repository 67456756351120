import { gql } from '@apollo/client'

export const QUERY_GET_ALL_USERS = gql`
  query FeedQuery {
    Users {
      _id
      username
      first_name
      last_name
      nick_name
      is_active
      last_update(format: "DD/MM/YYYY HH:mm:ss")
    }
  }
`
export const QUERY_GET_USER_BY_ID = gql`
  query FeedQuery($_id: ID!) {
    User(_id: $_id) {
      _id
      first_name
      username
      last_name
      nick_name
      email
      bank_account
      telephone
      team
      is_active
      address
      profile_image
      description
      roles
      role_name
    }
  }
`
export const MUTATION_ADD_USER = gql`
  mutation AddUser(
    $first_name: String!
    $username: String!
    $password: String!
    $roles: [String]
    $last_name: String
    $nick_name: String
    $email: String
    $bank_account: String
    $telephone: String
    $team: String
    $is_active: String
    $address: String
    $description: String
    $role_name: String
  ) {
    AddUser(
      first_name: $first_name
      username: $username
      password: $password
      roles: $roles
      last_name: $last_name
      nick_name: $nick_name
      email: $email
      bank_account: $bank_account
      telephone: $telephone
      team: $team
      is_active: $is_active
      address: $address
      description: $description
      role_name: $role_name
    ) {
      statusCode
      message
      data
    }
  }
`
export const MUTATION_EDIT_USER = gql`
  mutation EditUser(
    $_id: ID
    $first_name: String
    $username: String
    $password: String
    $roles: [String]
    $last_name: String
    $nick_name: String
    $email: String
    $bank_account: String
    $telephone: String
    $team: String
    $is_active: String
    $address: String
    $profile_image: String
    $description: String
    $role_name: String
  ) {
    EditUser(
      _id: $_id
      first_name: $first_name
      username: $username
      password: $password
      roles: $roles
      last_name: $last_name
      nick_name: $nick_name
      email: $email
      bank_account: $bank_account
      telephone: $telephone
      team: $team
      is_active: $is_active
      address: $address
      profile_image: $profile_image
      description: $description
      role_name: $role_name
    ) {
      statusCode
      message
    }
  }
`

export const MUTATION_LOGIN_USER = gql`
  mutation LoginUser($username: String!, $password: String!) {
    LoginUser(username: $username, password: $password) {
      statusCode
      message
      user {
        _id
        first_name
        last_name
      }
      token
      type
    }
  }
`

export const MUTATION_CHANGE_PASSWORD = gql`
  mutation ($_id: ID!, $password: String, $confirm_password: String) {
    ChangePassword(
      _id: $_id
      password: $password
      confirm_password: $confirm_password
    ) {
      statusCode
      message
      data
    }
  }
`

export const QUERY_GET_USER_PROFILE = gql`
  query FeedQuery($_id: ID!) {
    UserProfile(_id: $_id) {
      _id
      first_name
      username
      last_name
      nick_name
      email
      bank_account
      telephone
      team
      is_active
      address
      profile_image
      description
      roles
      role_name
    }
  }
`

export const MUTATION_EDIT_USER_PROFILE = gql`
  mutation EditUserProfile(
    $_id: ID
    $first_name: String
    $username: String
    $password: String
    $roles: [String]
    $last_name: String
    $nick_name: String
    $email: String
    $bank_account: String
    $telephone: String
    $team: String
    $is_active: String
    $address: String
    $profile_image: String
    $description: String
    $role_name: String
  ) {
    EditUserProfile(
      _id: $_id
      first_name: $first_name
      username: $username
      password: $password
      roles: $roles
      last_name: $last_name
      nick_name: $nick_name
      email: $email
      bank_account: $bank_account
      telephone: $telephone
      team: $team
      is_active: $is_active
      address: $address
      profile_image: $profile_image
      description: $description
      role_name: $role_name
    ) {
      statusCode
      message
    }
  }
`

export const MUTATION_GEN_USER_TOKEN_STORYTELLING = gql`
  mutation GenUserTokenStorytelling(
    $_id: ID!
    $user_id: ID!
    $username: String
    $user_token: String
    $content_id: String
    $preview_url: String
  ) {
    GenUserTokenStorytelling(
      _id: $_id
      user_id: $user_id
      username: $username
      user_token: $user_token
      content_id: $content_id
      preview_url: $preview_url
    ) {
      statusCode
      message
      token
    }
  }
`

export const MUTATION_VERIFY_G2FA = gql`
  mutation VerifyG2fa($username: String!, $number: Int!) {
    VerifyG2fa(username: $username, number: $number) {
      statusCode
      message
      user {
        _id
        first_name
        last_name
      }
      token
    }
  }
`