import { makeStyles } from '@material-ui/core'
import React, { ReactElement, useState } from 'react'
import Header from '@Components/Layout/Header'
import { styles } from '@Components/Layout/layout.style'
import Sidebar from '@Components/Layout/Sidebar'
import { AuthContextType, useAuth } from '@Hooks/useAuth'
import Login from '../pages/Login'
import Routes from '../routes/Routes'
import clsx from 'clsx'
import { useGlobal } from '@Hooks/useGlobal'
import G2FA from './G2FA'

const useStyles = makeStyles(styles)
export default function Pages(): ReactElement {
  const classes = useStyles()
  const { openMenu } = useGlobal()
  const { user } = useAuth() as AuthContextType
  const [isG2FA, setIsG2FA] = useState(false)
  const [username, setUsername] = useState('')

  return (
    <>
      {!user ? (
        <>
        {!isG2FA ? (
          <Login
            onSuccess={(username) => {
              setUsername(username)
              setIsG2FA(true)
            }}
          />
        ) : (
          <G2FA _username={username} onEnd={() => setIsG2FA(false)} />
        )}
      </>

        
      ) : (
        <div className={classes.root}>
          <div
            className={clsx(classes.container, {
              [classes.container_shift]: openMenu,
            })}
          >
            <Header />
            <main className={clsx(classes.main)}>
              <Routes />
            </main>
          </div>
          <Sidebar />
        </div>
      )}
    </>
  )
}
